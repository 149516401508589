import { Container } from '@mui/material';
function HeaderBar() {

  return (
    <Container className='navbar'>
      <h1 className='head'>Chevron React </h1>
    </Container>
        

  );
}
export default HeaderBar